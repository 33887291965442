<template>
  <div>
    <div class="service_list_box">
      <div class="service_item_list">
        <div

          class="row service_list_box"
        >
          <div
            class="col-12"
          >
            <div
              class="col-12 mb-2"
            >
              <div class="sorting_view_main_box">
                <div class="title_view">
                  <h4 class="search_result_text">
                    My Businesses({{ business_profiles.length }})
                  </h4>
                  <!-- v-b-tooltip.hover.top="'sort alphabetically'" -->
                  <div

                    class="sort_item_box_view"
                    @click="sortData"
                  >
                    <img
                      v-if="sort_type === 'DESC'"
                      :src="SortDown"
                      alt="sort"
                    >
                    <img
                      v-if="sort_type === 'ASC'"
                      v-b-tooltip.hover.top="'sort alphabetically'"
                      :src="SortUp"
                      alt="sort"
                    >
                  </div>
                </div>

                <div class="search-input-view">
                  <b-form-input
                    id="h-search-keyword"
                    v-model="searchTerm"
                    type="text"
                    placeholder="search business"
                    class="form-control"
                    style="min-width: 450px;"
                  />
                </div>
              </div>
              <!-- Sorting view -->

              <!-- <img
              v-if="sort_type === 'ASC'"
              v-b-tooltip.hover.top="'sort alphabetically'"
              :src="SortUp"
              alt="sort"
            > -->
              <!-- sorting view end -->
            </div>
            <div
              class="row"
            >

              <div
                v-for="(item, index) in business_profiles"

                :key="index"
                class="col-12 col-md-6 col-lg-4 mb-2"
                @click="showDetails(item)"
              >
                <div class="service_card_box">
                  <div class="service_image_box">
                    <div
                      v-lazy:background-image="getImage(item.cover)"
                      class="service_image"
                    >
                      <!-- <div
                        class="tag_box"
                      >
                        <div
                          v-b-tooltip.hover.top="item.favorite ? 'Remove from favourite' : 'Add to favourite'"
                          class="book-mark-box"
                          :class="item.favorite ? 'like' : ''"
                          @click.stop="addToFavourite(item)"
                        >
                          <img
                            v-if="item.favorite"
                            :src="FavoriteIcon"
                            alt="sort"
                          >
                          <img
                            v-else
                            :src="FavoriteIcon2"
                            alt="sort"
                          >
                        </div>

                        <div
                          v-b-tooltip.hover.top="'Recommend Service'"
                          class="book-mark-box"
                          @click.stop="showRecomModal(item.id)"
                        >
                          <feather-icon icon="CornerUpRightIcon" />
                        </div>
                      </div> -->
                    </div>
                  </div>

                  <div class="card_details">
                    <div class="tag-view">

                      <!-- <span> {{ item.category }}</span>
                      <span>{{ item.state }}</span> -->
                    </div>

                    <h3 class="list_heading">
                      {{ item.name | setTruncate(34, "...") }}
                    </h3>

                    <div class="review_view">
                      <span />
                      <span>({{ item.total_reviews > 0 ? item.total_reviews : 'No' }} {{ item.total_reviews > 1 ? 'reviews' : 'review' }})</span>
                      <b-form-rating
                        v-model="item.average_rating"
                        variant="warning"
                        inline
                        no-border
                        readonly
                        class="raiting_box"
                      />
                      <span v-if="item.average_rating > 0">{{ item.average_rating }} out 5</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- <div
        v-if="business_profiles.length >= 12 || profile_param.page > 1"
        class="pagination_box"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="profile_params.per_page"
          size="lg"
          align="center"
          @change="onPageChange"
        />
      </div> -->

    </div>
    <b-modal
      id="app-modal"
      :title="is_update ? 'Uptate Business Profile' : 'Add New Business Profile'"
      hide-footer
      size="huge"
      @hide="hide"
    >
      <b-card-text>
        <div class="bp_form_box">
          <div class="bp_form_side_bar">
            <div
              v-for="(data, index) in tab_items"
              :key="index"
              class="bp_tab_link"
              :class="is_tab_active === data.id ? 'bp_active' : ''"
              @click="showFormContent(data.id)"
            >
              <div class="bp_tab_counter">
                {{ index + 1 }}
              </div>
              <div class="bp_text_view">
                <span> {{ data.title }} </span>
              </div>
            </div>
          </div>
          <div class="bp_form_view">
            <div class="form_content">
              <validation-observer ref="profileForm">
                <b-form>
                  <b-row v-if="is_tab_active === 1">
                    <b-col
                      cols="12"
                      class="mt-2"
                    >

                      <span>Members</span>
                      <b-form-group
                        label=""
                        label-for="h-member"
                        label-cols-md="12"
                      >
                        <b-form-select
                          id="h-member"
                          v-model="profile.member_id"
                          :options="user_options"
                          disabled
                        />
                      </b-form-group>
                      <hr>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        label="Business Name"
                        label-for="h-name"
                        label-cols-md="12"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="name"
                          rules="required"
                        >
                          <b-form-input
                            id="h-name"
                            v-model="profile.name"
                            type="text"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="6">
                      <b-form-group
                        label="Category "
                        label-for="h-categories"
                        label-cols-md="12"
                      >
                        <b-form-select
                          id="h-categories"
                          v-model="profile.category_id"
                          :options="categories_options"
                          @change="changeCategory"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col cols="6">
                      <b-form-group
                        label="Business Email"
                        label-for="h-email"
                        label-cols-md="12"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="email"
                          rules="required|email"
                        >
                          <b-form-input
                            id="h-email"
                            v-model="profile.email"
                            type="email"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Email"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- <b-col cols="6">

                      <b-form-group
                        label="Sub Categories "
                        label-for="h-sub-categories"
                        label-cols-md="12"
                      >
                        <b-form-select
                          id="h-sub-categories"
                          v-model="profile.sub_category_id"
                          :options="sub_categories_options"
                        />
                      </b-form-group>
                    </b-col> -->
                    <b-col cols="6">
                      <b-form-group
                        label="Business Phone"
                        label-for="h-phone"
                        label-cols-md="12"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="phone"
                          rules="required"
                        >
                          <b-form-input
                            id="h-phone"
                            v-model="profile.phone"
                            type="text"
                            :state="errors.length > 0 ? false:null"
                            placeholder="phone"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- <b-col cols="6">
                      <b-form-group
                        label="License"
                        label-for="h-license"
                        label-cols-md="12"
                      >
                        <b-form-checkbox
                          :checked="has_license ? true : false"
                          name="check-button"
                          switch
                          inline
                          :class="has_license ? 'mb-2' : ''"
                          @change="changeHasLicense"
                        >
                          has license
                        </b-form-checkbox>
                        <b-form-input
                          v-if="has_license"
                          id="h-license"
                          v-model="profile.license"
                          placeholder="license"
                        />
                      </b-form-group>
                    </b-col> -->
                    <!-- <b-col cols="6">
                      <b-form-group
                        label="Tags"
                        label-for="h-tags"
                        label-cols-md="12"
                      >
                        <MultipleSelect
                          :options="tag_options"
                          :select-options="selectedTags"
                          :remove="removeTags"
                          :selected="selectTags"
                        />
                      </b-form-group>
                    </b-col> -->
                    <b-col cols="6">
                      <b-form-group
                        label="Business Website"
                        label-for="h-website"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-website"
                          v-model="profile.website"
                          placeholder="website"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        label="Contact Person Name"
                        label-for="h-contact_name"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-contact_name"
                          v-model="profile.contact_name"
                          placeholder="Contact person title"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        label="Contact Person Title"
                        label-for="h-contact_person_title"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-contact_person_title"
                          v-model="profile.contact_title"
                          placeholder="Contact person title"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        label="Contact Person Phone"
                        label-for="h-contact_person_phone"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-contact_person_phone"
                          v-model="profile.contact_phone"
                          placeholder="Contact person phone"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        label="Contact Person Email"
                        label-for="h-contact_person_email"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-contact_person_email"
                          v-model="profile.contact_email"
                          placeholder="Contact person email"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                      <h5>Active State(s)</h5>
                      <div class="state_tag_view banner_service__offter">
                        <b-badge
                          v-for="(item, index) in profile.authorized_states"
                          :key="index"
                          class="mr-1 mb-1"
                          icon="MapPinIcon"
                          variant="secondary"
                        >
                          <feather-icon
                            icon="MapPinIcon"
                            class="mr-25"
                          /> {{ item.state.name }}
                        </b-badge>
                      </div>
                    </b-col>
                  </b-row>

                  <b-row v-if="is_tab_active === 2">
                    <!-- <b-col
                      cols="12"
                      class=""
                    >
                      <b-form-group
                        label="Find location"
                        label-for="h-latitude"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-latitude"
                          v-model="map_address"
                          placeholder="Enter Address to find location"
                        /></b-form-group>
                    </b-col>

                    <b-col
                      cols="6"
                      class=""
                    >
                      <b-form-group
                        label="Latitude"
                        label-for="h-latitude"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-latitude"
                          v-model="profile.lat"
                          placeholder="Enter Latitude"
                        /></b-form-group>
                    </b-col>

                    <b-col
                      cols="6"
                      class=""
                    >
                      <b-form-group
                        label="Logitude"
                        label-for="h-longitude"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-longitude"
                          v-model="profile.lng"
                          placeholder="Enter Longitude"
                        /></b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-success"
                        class="mt-2 mb-2"
                        block
                        @click="findCords"
                      >
                        Find Location
                      </b-button>
                    </b-col> -->

                    <b-col
                      cols="12"
                      class="mt-4"
                    >
                      <ServiceArea
                        :id="profile.id"
                        :update="is_update"
                        :category="profile.category_id"
                        :authorized-states="profile.authorized_states"
                      />
                    </b-col>
                    <!-- <b-col cols="12">
                      <div
                        v-if="is_tab_active === 2"
                        ref="mapContainer"
                        style="height: 380px; width: 100%"
                      />
                    </b-col> -->

                  </b-row>

                  <b-row v-if="is_tab_active === 3">
                    <b-col cols="12">
                      <ServiceOffer
                        :id="profile.id"
                        :update="is_update"
                        :category="profile.category_id"
                      />
                    </b-col>
                  </b-row>

                  <b-row v-if="is_tab_active === 4">
                    <b-col cols="12">
                      <quill-editor
                        v-model="profile.description"
                        style="height: 500px !important; min-height: 500px !important; margin-bottom: 100px !important"
                      />
                    </b-col>
                  </b-row>

                  <b-row v-if="is_tab_active === 5">
                    <b-col cols="12">
                      <WorkingHours
                        :id="profile.id"
                        :update="is_update"
                      />
                    </b-col>
                  </b-row>
                  <b-row v-if="is_tab_active === 6">
                    <b-col cols="12">
                      <License
                        :id="profile.id"
                        :update="is_update"
                      />
                    </b-col>
                  </b-row>
                  <b-row v-if="is_tab_active === 7">
                    <b-col cols="12">
                      <Address />
                    </b-col>
                  </b-row>
                  <b-row v-if="is_tab_active === 8">
                    <b-col cols="12">
                      <Gallery
                        :id="profile.id"
                        :cover="profile.cover"
                        :gallery="profile.gallery"
                        :update="is_update"
                        :thumb="true"
                      />
                    </b-col>
                    <b-col cols="12">
                      <Gallery
                        :id="profile.id"
                        :gallery="profile.gallery"
                        :update="is_update"
                        :hide-edited="hideEdited"
                      />
                    </b-col>
                  </b-row>
                  <b-row v-if="is_tab_active === 9 && is_update">
                    <b-col
                      v-if="review_page < 2 && profile_reviews.length < 3 "
                      cols="12"
                    >
                      <div
                        class="sharemain_box"
                        @click="openshareBusiness"
                      >
                        <div
                          v-b-tooltip.hover.top="'Share link to this business'"
                          class="share_main_icon_box"
                        >
                          <feather-icon
                            icon="Share2Icon"
                            size="40"
                          />
                        </div>
                        <h4>Review for this business: {{ profile_reviews.length }}, <br>share this business to get more reviews</h4>
                      </div>
                      <b-col cols="12">

                        <b-modal
                          id="share-business-modal"
                          title="Share a link to my business profile"
                          hide-footer
                        >
                          <b-card-text>
                            <h5>Select member</h5>
                            <div class="search_user_input">

                              <v-select
                                v-model="search_user"
                                label="name"
                                :filterable="false"
                                :options="options"
                                @search="onSearch"
                              >
                                <template slot="no-options">
                                  type to search members
                                </template>
                                <template
                                  slot="option"
                                  slot-scope="option"
                                >
                                  <div class="d-center">
                                    <!-- <img :src="option.owner.avatar_url"> -->
                                    {{ option.name }}
                                  </div>
                                </template>
                                <!-- <template
                                  slot="selected-option"
                                  slot-scope="option"
                                >
                                  <div class="selected d-center">
                                    <img :src="option.owner.avatar_url">
                                    {{ option.full_name }}
                                  </div>
                                </template> -->
                              </v-select>
                            </div>
                            <div
                              class="button_view d-flex align-items-center justify-content-between mt-4"
                            >
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-secondary"
                                @click="$bvModal.hide('share-business-modal')"
                              >
                                Cancel
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-success"
                                @click="shareBusiness"
                              >
                                Share link
                              </b-button>
                            </div>
                          </b-card-text>
                        </b-modal>
                      </b-col>
                    </b-col>

                    <b-col
                      v-else
                      cols="12"
                    >
                      <div
                        v-for="(review, index) in profile_reviews"
                        :key="index"
                        class="business_reviw__card"
                      >
                        <div class="reviewer_view">
                          <b-avatar
                            v-if="review.reviewer.avatar === null || review.reviewer.avatar === ''"
                            variant="success"
                            :text="getUserText(review.reviewer.first_name, review.reviewer.last_name)"
                            size="50"
                          />
                          <b-avatar
                            v-else
                            :src="getImage(review.reviewer.avatar)"
                            size="50"
                          />
                          <div class="review-heading_box">
                            <div class="heading">
                              <h4>{{ review.reviewer.first_name }} {{ review.reviewer.last_name }}, {{ review.reviewer.profession }}</h4>
                              <p style="margin-top: -10px;"> <b-form-rating
                                variant="warning"
                                no-border
                                :value="review.rating"
                                readonly
                                inline
                                class="raiting_box"
                                style="border:none !important; padding-left: 0 !important;"
                              /></p>
                            </div>
                            <div>    <b-badge
                              :variant="review.status === 'active' ? 'light-success':
                                review.status === 'reported' ? 'light-danger' : review.status === 'under review' ? 'light-warning' : 'light-secondary'
                              "
                            >
                              {{ review.status }}
                            </b-badge></div>

                          </div>

                        </div>

                        <p>{{ review.review }}</p>
                        <hr>
                        <div class="row">
                          <div class="col-md-4">
                            <h5>Customer Service</h5>
                            <span>{{ review.content.customer_service }}</span>
                          </div>

                          <div class="col-md-3">
                            <h5>Pricing</h5>
                            <span>{{ review.content.pricing }}</span>
                          </div>

                          <div class="col-md-3">
                            <h5>Quality</h5>
                            <span>{{ review.content.quality }}</span>
                          </div>

                          <div class="col-md-2">
                            <h5>Timeliness</h5>
                            <span>{{ review.content.timeliness }}</span>
                          </div>

                          <div class="col-md-3 mt-2">
                            <h5>Recommendation</h5>
                            <span>{{ review.content.recommend }}</span>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row v-if="is_tab_active === 10 && is_update">
                    <b-col cols="12">
                      <!-- <h4>System Status</h4>
                      <b-form-select
                        v-model="profile.system_status"
                        :options="system_options"
                        class="mb-2"
                        disabled
                      /> -->
                      <hr>
                      <h4 class="d-flex align-items-center justify-content-between">
                        <span>Application Status</span>
                        <b-badge
                          :variant="application_status.status === 'active' ? 'light-success':
                            application_status.status === 'cancel' ? 'light-danger' : application_status.status === 'waiting for review' ? 'light-warning' : 'light-secondary'
                          "
                          style="text-transform: capitalize !important;"
                        >
                          {{ application_status.status }}
                        </b-badge>
                      </h4>
                      <b-alert
                        v-if="application_status.reason.length > 0"
                        variant="warning"
                        show
                      >
                        <h4 class="alert-heading">
                          Reason
                        </h4>
                        <div class="alert-body">
                          {{ application_status.reason }}
                        </div>
                      </b-alert>
                      <br>
                      <!-- <b-form-select
                        v-model="profile.application_status"
                        :options="application_options"
                        class="mb-4"
                        @change="changeApplicationStatus"
                      /> -->
                      <b-button
                        v-if="application_status.status !== 'waiting for review'"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        @click="setAsInactiveData"
                      >
                        {{ application_status.status === 'active' ? 'Set as Inactive' : 'Set as Active' }}
                      </b-button>
                      <b-modal
                        id="status-modal"
                        title="Change Status"
                        hide-footer
                      >
                        <b-card-text>
                          <h5>Enter the reason why you want to change status. </h5>

                          <validation-observer ref="applicationStatusForm">
                            <b-form @submit.prevent="changeApplicationStatus">
                              <b-row>
                                <!-- first name -->
                                <b-col cols="12">
                                  <b-form-group
                                    label=""
                                    label-for="v-reason"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="reason"
                                      rules="required"
                                    >
                                      <b-form-textarea
                                        id="v-reason"
                                        v-model="application_status.reason"
                                        placeholder=""
                                        rows="5"
                                        :state="errors.length > 0 ? false:null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <div

                                class="button_view d-flex align-items-center justify-content-between"
                              >
                                <div />

                                <b-button
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  variant="outline-success"
                                  type="submit"
                                >
                                  Change status
                                </b-button>
                              </div>
                            </b-form>
                          </validation-observer>
                        </b-card-text>

                      </b-modal>

                    </b-col>
                  </b-row>

                  <b-row v-if="is_tab_active === 11 && is_update">
                    <b-col
                      v-if="transactions.length < 1"
                      cols="12"
                    >
                      <div

                        class="sharemain_box"
                      >
                        <div
                          v-b-tooltip.hover.top="'Add transaction to this business'"
                          class="share_main_icon_box"
                        >
                          <feather-icon
                            icon="ClipboardIcon"
                            size="60"
                          />
                        </div>
                        <h4>Transaction for this business: {{ transactions.length }}</h4>
                      </div>
                    </b-col>
                    <b-col
                      v-else
                      cols="12"
                    >
                      <!-- {{ transactions }} -->
                      <b-row>
                        <b-col
                          v-for="(transaction, index) in transactions"
                          :key="index"
                          cols="12"
                        >
                          <div class="transaction_list_card mb-2">
                            <div class="heading-text-view">
                              <div class="staff-item-box">
                                <b-avatar
                                  v-if="transaction.staff.avatar === null || transaction.staff.avatar === ''"
                                  :text="getUserText(transaction.staff.first_name, transaction.staff.last_name)"
                                  variant="success"
                                />
                                <b-avatar
                                  v-else
                                  :src="getImage(transaction.staff.avatar)"
                                  variant="light-success"
                                />

                                <div class="created_By_box">
                                  <h5>Created By</h5>
                                  <span class="user_name">{{ transaction.staff && transaction.staff.first_name }} {{ transaction.staff && transaction.staff.last_name }}</span>
                                </div>
                              </div>
                              <div>
                                <h5>Created At</h5>
                                <span class="date-text">{{ date(transaction.transaction_date) }}</span>
                              </div>

                            </div>

                            <hr>
                            <b-alert
                              variant="secondary"
                              show
                            >
                              <h5 class="alert-heading">
                                Transaction Number
                              </h5>
                              <div class="alert-body">
                                <span>{{ transaction.transaction_number }}</span>
                              </div>

                            </b-alert>
                            <hr>

                            <div class="date-heading-view">
                              <div>
                                <h5>Start Date:</h5>
                                <span>{{ date(transaction.start_date) }}</span>
                              </div>
                              <feather-icon icon="GitCommitIcon" />
                              <div>
                                <h5>End Date:</h5>
                                <span>{{ date(transaction.end_date) }}</span>
                              </div>
                            </div>

                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </div>
            <div
              v-if="is_update && is_tab_active === 1 || is_update && is_tab_active === 4 || is_update && is_tab_active === 7"
              class="button_view d-flex align-items-center justify-content-between"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                :disabled="is_edited === false && is_update ? true : false"
                @click="is_update ? updateBusinessProfile() : createBusinessProfile()"
              >
                Save Changes
              </b-button>
            </div>

            <div
              v-if="is_tab_active === 9 && is_update"
              class="button_view"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalReviewRows"
                :per-page="reviewPerpage"
                size="lg"
                class="mt-3 pagination-success"
                align="center"
                @change="onReviewPageChange"
              />
            </div>

          </div>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, VBTooltip, BButton, BModal, VBModal, BCardText, BTable,
  BRow, BCol, BFormGroup, BFormInput, BFormRating, BForm, BFormSelect, BAlert, BBadge, BFormCheckbox, BPagination, BAvatar, BFormTextarea,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import WorkingHours from '../../app/components/WorkingHours.vue'
import License from '../../app/components/License.vue'
import Address from '../../app/components/Address.vue'
import Gallery from '../../app/components/Gallery.vue'
import MultipleSelect from '../../../@core/components/MultipleSelect.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import ServiceArea from '../../app/components/ServiceArea.vue'
import ServiceOffer from '../../app/components/ServiceOffer.vue'
import SortDown from '../../../assets/images/icons/sortdown.png'
import SortUp from '../../../assets/images/icons/sortup.png'
import { notifyMe } from '@/libs/toast'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormRating,
    BBadge,
    BAlert,
    BFormTextarea,
    BBadge,
    BForm,
    BPagination,
    quillEditor,
    BAvatar,
    WorkingHours,
    ValidationProvider,
    ValidationObserver,
    License,
    Address,
    Gallery,
    MultipleSelect,
    ServiceArea,
    ServiceOffer,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      options: [],
      SortUp,
      SortDown,
      is_update: false,
      has_license: false,
      selectedTags: [],
      authorize_state: ['Virginia', 'District of Columbia', 'Maryland'],
      required,
      perPage: 10,
      filter: null,
      sortBy: 'id',
      sortDesc: false,
      filterOn: [],
      pageOptions: [3, 5, 10],
      is_edited: false,
      currentPage: 1,
      email,
      longitude: -78.024902,
      latitude: 37.926868,
      map: null,
      map_address: '',
      fields: [{ key: 'cover', label: 'Cover Photo' }, { key: 'name', label: 'Name', sortable: true }, { key: 'state_id', label: 'State' }, { key: 'category_id', label: 'Category' }, { key: 'status', label: 'Status' }],
      selected: null,
      review_page: 1,
      reviewPerpage: 5,
      isModalShow: false,
      selected_user: null,
      selected_tags: [],
      sort_type: 'DESC',
      application_status: {
        status: '',
        reason: '',
        status_type: 'application_status',
      },
      profile: {
        member_id: 1,
        name: '',
        phone: '',
        email: '',
        city: '',
        address: '',
        state_id: null,
        county_id: null,
        zip_code: '',
        lng: null,
        lat: null,
        license: '',
        website: '',
        category_id: 1,
        sub_category_id: null,
        tags: [],
        contact_name: '',
        contact_title: '',
        contact_email: '',
        contact_phone: '',
        description: '',
        system_status: null,
        application_status: null,
      },
      searchTerm: '',
      tab_items: [
        { id: 1, title: 'Infomation' },
        { id: 2, title: 'Area Served' },
        { id: 3, title: 'Services Offered' },
        { id: 4, title: 'Description' },
        { id: 5, title: 'Business Hours' },
        { id: 7, title: 'Address' },
        { id: 8, title: 'Gallery' },
        { id: 9, title: 'Reviews' },
      ],
      is_tab_active: 1,
      map_enable: false,
      search_user: '',
      system_options: [
        { value: 'active', text: 'active' },
        { value: 'waiting for review', text: 'waiting for review' },
        { value: 'cancel', text: 'cancel' },
      ],
      application_options: [
        { value: 'active', text: 'active' },
        { value: 'waiting for review', text: 'waiting for review' },
        { value: 'blocked by vendor', text: 'blocked by vendor' },
        { value: 'cancel', text: 'cancel' },
      ],
      status_text: '',
    }
  },
  computed: {
    business_profiles() {
      const items = this.$store.state.businessProfile.admin_business_profiles
      return items.filter(item => item.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
    },
    userData() {
      return this.$store.state.user.profile
    },
    totalRows() {
      return this.business_profiles.length
    },
    profile_reviews() {
      return this.$store.state.review.business_profile_reviews
    },
    totalReviewRows() {
      return this.$store.state.review.total_reviews
    },
    profile_params() {
      return this.$store.state.businessProfile.admin_param
    },
    hours: {
      get() {
        return this.$store.state.workingHours.hours
      },
      set(value) {
        return value
      },
    },
    socia_media: {
      get() {
        return this.$store.state.socialMedia.socia_media
      },
      set(value) {
        return value
      },
    },
    maps: {
      get() {
        return this.$store.state.businessProfile.maps
      },
      set(value) {
        return value
      },
    },
    gallery_items() {
      return this.$store.state.businessProfile.gallery_items
    },
    cover_photo() {
      return this.$store.state.businessProfile.cover_photo
    },
    transactions() {
      return this.$store.state.transaction.business_transaction
    },
    address: {
      get() {
        return this.$store.state.businessProfile.address
      },
      set(value) {
        return value
      },
    },
    licenses: {
      get() {
        return this.$store.state.license.licenses
      },
      set(value) {
        return value
      },
    },
    users() {
      const member = this.$store.state.user.members
      return member
    },
    user_options() {
      return this.users.map(item => {
        const data = { value: item.id, text: `${item.first_name} ${item.last_name}` }
        return data
      })
    },
    categories() {
      return this.$store.state.category.categories
    },
    sub_categories() {
      return this.$store.state.subCategory.sub_categories
    },
    categories_options() {
      return this.categories.map(item => {
        const data = { value: item.id, text: item.name }
        return data
      })
    },
    sub_categories_options() {
      return this.sub_categories.map(item => {
        const data = { value: item.id, text: item.name }
        return data
      })
    },
    tags() {
      return this.$store.state.tag.tags
    },
    tag_options() {
      return this.tags.map(item => {
        const data = { title: item.name, id: item.id }
        return data
      })
    },
    states() {
      return this.$store.state.state.states
    },
    service_area: {
      get() {
        return this.$store.state.serviceArea.service_area
      },
      set(value) {
        return value
      },
    },
    service_offer: {
      get() {
        return this.$store.state.serviceOffer.service_offer
      },
      set(value) {
        return value
      },
    },
    allCounties() {
      return this.$store.state.county.all_counties
    },
  },
  watch: {
    // latitude(newValue) {
    //   if (this.map_enable === true) {
    //     this.map.setView([newValue, this.longitude], 13)
    //   }
    // },
    // longitude(newValue) {
    //   if (this.map_enable === true) {
    //     this.map.setView([this.latitude, newValue], 13)
    //   }
    // },
    is_tab_active(newValue) {
      if (newValue === 2) {
        const dom = this
        setTimeout(() => {
          // dom.initMap()
          // dom.map_enable = true
        }, 300)
      } else {
        // eslint-disable-next-line keyword-spacing, no-lonely-if
        if(this.map_enable === true) {
          this.map_enable = false
          this.clearMap()
        }
      }
    },
    '$store.state.businessProfile.gallery_item': {
      handler(oldValue, newValue) {
        newValue.forEach(item => {
          this.profile.gallery.push(item)
          this.is_edited = false
        })
      },
      deep: true,
    },
    '$store.state.businessProfile.cover_photo': {
      handler() {
        if (this.is_update === true) {
          this.is_edited = true
        }
      },
      deep: true,
    },
    profile: {
      handler() {
        if (this.is_update === true) {
          this.is_edited = true
        }
      },
      deep: true,
    },
    '$store.state.businessProfile.address': {
      handler() {
        if (this.is_update === true) {
          this.is_edited = true
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getBusinessProfile()
    this.getCategory()
    this.getUsers()
    this.getDays()
  },
  methods: {
    getCategory() {
      return this.$store.dispatch('category/getCategories')
    },
    // initMap() {
    //   this.map = L.map(this.$refs.mapContainer).setView(
    //     [this.latitude, this.longitude],
    //     13,
    //   )
    //   L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //     attribution:
    //     '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    //   }).addTo(this.map)

    //   L.marker([this.latitude, this.longitude], {
    //     icon: L.icon({
    //       // eslint-disable-next-line global-require
    //       iconUrl: require('leaflet/dist/images/marker-icon.png'),
    //       iconSize: [26, 37],
    //       iconAnchor: [22, 94],
    //       popupAnchor: [-3, -76],
    //     }),
    //   }).addTo(this.map)
    // },
    // clearMap() {
    //   this.map.remove() // remove the map instance before the component is destroyed
    //   this.map = null
    // },
    getDays() {
      return this.$store.dispatch('businessProfile/getDays')
    },
    // findCords() {
    //   const params = {
    //     text: this.map_address,
    //     apiKey: 'b8c01cc7450d4dc8851b6708190ed62c',
    //   }

    //   axios.get('https://api.geoapify.com/v1/geocode/search', { params })
    //     .then(response => {
    //       const property = response.data.features[0].properties
    //       // Extract the latitude and longitude from the first result
    //       const latitude = property.lat
    //       const longitude = property.lon
    //       this.map.setView([latitude, longitude], 13)
    //       L.marker([latitude, longitude], {
    //         icon: L.icon({
    //           // eslint-disable-next-line global-require
    //           iconUrl: require('leaflet/dist/images/marker-icon.png'),
    //           iconSize: [26, 37],
    //           iconAnchor: [22, 94],
    //           popupAnchor: [-3, -76],
    //         }),
    //       }).addTo(this.map)
    //       this.profile.lng = longitude
    //       this.profile.lat = latitude
    //     }).catch(() => {
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Address not found',
    //           icon: 'AlertTriangleIcon',
    //           variant: 'danger',
    //         },
    //       })
    //     })
    // },
    getBusinessProfile() {
      return this.$store.dispatch('businessProfile/getProfileVendor')
    },
    onPageChange(page) {
      this.profile_param.page = page
      this.getBusinessProfile()
    },
    sortData() {
      if (this.sort_type === 'DESC') {
        this.sort_type = 'ASC'
      } else if (this.sort_type === 'ASC') {
        this.sort_type = 'DESC'
      }
      this.$store.dispatch('businessProfile/sortVendorBusiness', { data: this.business_profiles, sort_type: this.sort_type })
    },
    getImage(image) {
      if (image !== null || image !== '') {
        return process.env.VUE_APP_STORAGE_PATH + image
      }
      return image
    },
    date(date) {
      return moment(date).format('ddd DD-MMM-YY')
    },
    getBGImage(image) {
      if (image !== null || image !== '') {
        return `background: url(${process.env.VUE_APP_STORAGE_PATH + image})`
      }
      return image
    },
    showFormContent(id) {
      this.is_tab_active = id
    },
    getUsers() {
      return this.$store.dispatch('user/getMembers', { page: 1, per_page: 10, user_type: 'member' })
    },
    getSubCategory(id) {
      return this.$store.dispatch('subCategory/getSubCategories', id)
    },
    changeCategory(item) {
      const categoryItem = this.categories.filter(elm => elm.id === item)
      if (categoryItem[0].state_license_required === false) {
        const tabs6 = this.tab_items.filter(tab => tab.id === 6)
        if (tabs6.length === 0) {
          const data = { id: 6, title: 'License' }
          this.tab_items.splice(1, 0, data)
        }
      }
    },
    showModal() {
      this.$bvModal.show('app-modal')
      this.isModalShow = true
    },
    hide(e) {
      if (this.is_edited === true) {
        e.preventDefault()
        this.$swal({
          title: 'Do you want to save changes',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save Changes',
          cancelButtonText: 'Discard Changes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.is_edited = false
            this.updateBusinessProfile()
          } else if (result.dismiss === 'cancel') {
            this.is_edited = false
            this.$store.dispatch('socialMedia/emptySocialData')
            this.$store.dispatch('workingHours/emptyData')
            this.$store.dispatch('serviceArea/emptyServiceAreaData')
            this.$store.dispatch('serviceOffer/emptyServiceOfferData')
            this.$store.dispatch('businessProfile/emptyAddress')
            this.$store.dispatch('license/emptyLicenseData')
            this.$bvModal.hide('app-modal')
            this.isModalShow = false
            this.is_update = false
            this.emptyProfile()
            this.selectedTags = []
            this.map_address = ''
            this.is_tab_active = 1
            const newData = this.tab_items.filter(elm => elm.id !== 6)
            this.tab_items = newData
          }
        })
      } else {
        this.is_edited = false
        this.$store.dispatch('socialMedia/emptySocialData')
        this.$store.dispatch('workingHours/emptyData')
        this.$store.dispatch('serviceArea/emptyServiceAreaData')
        this.$store.dispatch('serviceOffer/emptyServiceOfferData')
        this.$store.dispatch('businessProfile/emptyAddress')
        this.$store.dispatch('license/emptyLicenseData')
        this.$bvModal.hide('app-modal')
        this.isModalShow = false
        this.is_update = false
        this.emptyProfile()
        this.selectedTags = []
        this.map_address = ''
        this.is_tab_active = 1
        const newData = this.tab_items.filter(elm => elm.id !== 6)
        this.tab_items = newData
      }
    },
    openshareBusiness() {
      this.$bvModal.show('share-business-modal')
    },
    hideEdited() {
      const dom = this
      setTimeout(() => {
        dom.is_edited = false
      }, 200)
    },
    shareBusiness() {
      this.$store.dispatch('businessProfile/shareBusiness', { business_profile_id: this.profile.id, emails: [this.search_user.email], name: this.search_user.name })
      this.$bvModal.hide('share-business-modal')
      this.search_user = ''
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: _.debounce((loading, search, vm) => {
      window.cxSocketApi.talk('find-members', response => {
        vm.options = response.data
        loading(false)
      }, error => notifyMe(error.data, 'danger'), search)
    }, 100),
    getCounty(id) {
      return this.$store.dispatch('county/getCounties', id)
    },
    toggleBusinessProfile(status, id) {
      const data = { business_profile_id: id, status }
      return this.$store.dispatch('businessProfile/toggleBusinessProfile', data)
    },
    removeTags(item) {
      if (this.is_update === true) {
        const data = {
          business_profile_id: this.profile.id,
          tag_id: item.id,
        }
        this.$store.dispatch('businessProfile/deleteTags', data)
        this.getBusinessProfile()
      }
      this.selectedTags = this.selectedTags.filter(elm => elm.id !== item.id)
    },
    addToFavourite(item) {
      const data = {
        member_id: this.userData.id,
        business_profile_id: item.id,
        favorite: !item.favorite,
      }

      this.$store.dispatch('businessProfile/addToFavourite', { item: data, param: this.profile_params })
      this.$store.dispatch('businessProfile/updateSearchResult', 'Favorites')
    },
    selectTags(item) {
      // eslint-disable-next-line camelcase
      const selected_data = this.selectedTags.filter(elm => elm.id === item.id)
      if (selected_data.length === 0) {
        this.selectedTags.push(item)
        if (this.is_update === true) {
          const data = {
            business_profile_id: this.profile.id,
            tags: [item.id],
          }
          this.$store.dispatch('businessProfile/addTags', data)
          this.getBusinessProfile()
        }
      }
    },
    showDetails(item) {
      const tabs = this.tab_items.filter(tab => tab.id === 9)
      if (tabs.length < 1) {
        this.tab_items.push({ id: 9, title: 'Reviews' })
      }
      const tabs10 = this.tab_items.filter(tab => tab.id === 10)
      if (tabs10.length < 1) {
        this.tab_items.push({ id: 10, title: 'Status Activities' })
      }
      const tabs11 = this.tab_items.filter(tab => tab.id === 11)
      if (tabs11.length < 1) {
        this.tab_items.push({ id: 11, title: 'Transactions' })
      }
      // this.getCategory()

      const details = item
      this.$store.dispatch('businessProfile/getProfileCoverPhoto', details.cover)
      this.profile = details
      this.address.address1 = details.address1
      this.map_address = details.address1
      this.longitude = details.lng
      this.latitude = details.lat
      this.address.city = details.city
      this.address.state_id = details.state_id
      this.address.zip_code = details.zip_code
      this.profile.lng = details.lng
      this.profile.lat = details.lat
      this.profile.system_status = details.system_status
      this.profile.application_status = details.application_status
      this.application_status.status = details.application_status
      this.application_status.reason = details.application_status_reason
      this.status_text = details.application_status_reason
      details.business_hours.forEach(elm => this.hours.push({
        id: elm.id,
        day_id: elm.day_id,
        business_hours_start: elm.business_hours_start,
        business_hours_end: elm.business_hours_end,
      }))
      const categoryItem = this.categories.filter(elm => elm.id === details.category_id)
      const isCatExit = categoryItem[0]?.state_license_required
      if (details.authorized_states.length < 1) {
        const newTab = this.tab_items.filter(tab => tab.id !== 2)
        this.tab_items = newTab
      } else {
        const newTab = this.tab_items.filter(tab => tab.id === 2)
        if (newTab.length < 1) {
          this.tab_items.splice(1, 0, { id: 2, title: 'Service Area' })
        }
      }
      if (isCatExit === false) {
        const tabs6 = this.tab_items.filter(tab => tab.id === 6)
        if (tabs6.length === 0) {
          const data = { id: 6, title: 'License' }
          this.tab_items.splice(1, 0, data)
        }
        details.licenses.forEach(elm => this.licenses.push({
          business_profile_id: elm.business_profile_id,
          id: elm.id,
          license: elm.license,
          license_expiration_date: elm.license_expiration_date,
          state: elm.state,
        }))
      }

      details.services.forEach(elm => this.service_offer.push({
        business_profile_id: elm.business_profile_id,
        id: elm.id,
        name: elm.service.name,
      }))
      details.service_areas.forEach(elm => {
        const countyOptions = this.allCounties.filter(filterData => filterData.state_id === elm.state_id)
        this.service_area.unshift({
          business_profile_id: elm.business_profile_id,
          id: elm.id,
          state_id: elm.state_id,
          county_id: elm.county_id,
          state_options: details.authorized_states.map(stateItem => ({ value: stateItem.state.id, text: stateItem.state.name })),
          county_option: countyOptions.map(options => ({ value: options.id, text: options.name })),
          category_id: details.category_id,
          is_update: true,
        })
      })

      // if (details.tags.length > 0) {
      //   this.selectedTags = details.tags.map(elm => ({ title: elm.name, id: elm.id }))
      // }

      if (details.county_id !== undefined || details.county_id !== null) {
        this.address.county_id = details.county_id
        this.getCounty(details.state_id)
      }

      if (details.license !== '') {
        this.has_license = true
      }
      // this.getSubCategory(details.category_id)
      this.is_update = true
      this.$bvModal.show('app-modal')
      this.isModalShow = true
      const dom = this
      // this.getCategory()
      setTimeout(() => {
        dom.is_edited = false
      }, 1000)

      this.$store.dispatch('review/getBusinessReviews', { page: 1, per_page: this.reviewPerpage, business_profile_id: item.id })
      this.$store.dispatch('transaction/getTransactionByBusinessProfile', details.id)
      // window.cxSocketApi.talk('get-vendor-business-details', response => {
      setTimeout(() => {
        this.review_page = 1
      }, 300)

      // }, error => notifyMe(error.data, 'danger'), item.id)
    },
    onReviewPageChange(page) {
      this.review_page = page
      return this.$store.dispatch('review/getBusinessReviews', { page, per_page: this.reviewPerpage, business_profile_id: this.profile.id })
    },
    changeHasLicense() {
      this.has_license = !this.has_license
    },
    getUserText(firstName, lastName) {
      const firstChar = firstName.charAt(0)
      const lastChar = lastName.charAt(0)
      return firstChar + lastChar
    },
    createBusinessProfile() {
      this.$refs.profileForm.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          delete this.profile.address
          if (this.profile.sub_category_id === null) {
            delete this.profile.sub_category_id
          }
          const data = this.profile
          data.city = this.address.city
          data.address1 = this.address.address1
          data.state_id = this.address.state_id
          data.county_id = this.address.county_id
          data.zip_code = this.address.zip_code
          data.lng = Number(this.longitude)
          data.lat = Number(this.latitude)
          data.gallery_items = this.gallery_items
          data.business_hours = this.hours
          data.social_handles = this.socia_media
          data.cover_photo = this.cover_photo
          data.services = this.service_offer
          data.service_areas = this.service_area.map(item => ({ state_id: item.state_id, county_id: item.county_id }))
          if (this.selectedTags.length > 0) {
            data.tags = this.selectedTags.map(item => item.id)
          }
          this.$store.dispatch('businessProfile/createProfile', { item: data, param: this.profile_params })
          this.hide()
        }
      })
    },
    updateBusinessProfile() {
      this.$refs.profileForm.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          const data = this.profile
          data.city = this.address.city
          data.address1 = this.address.address1
          data.state_id = this.address.state_id
          data.county_id = this.address.county_id
          data.zip_code = this.address.zip_code
          data.lng = Number(this.profile.lng)
          data.lat = Number(this.profile.lat)
          data.cover_photo = this.cover_photo
          if (data.cover_photo.name === '') {
            delete data.cover_photo
          }
          delete data.gallery
          delete data.created_at
          delete data.sub_category_id
          delete data.gallery_items
          delete data.business_hours
          delete data.licenses
          delete data.services
          delete data.service_areas
          delete data.cover
          this.$store.dispatch('businessProfile/updateProfile', { item: data, param: this.profile_params, user_type: 'vendor' })
          this.is_edited = false
          this.hide()
        }
      })
    },
    changeSystemStatus() {
      this.updateBusinessProfile()
    },
    changeApplicationStatus() {
      this.$refs.applicationStatusForm.validate().then(success => {
        if (success) {
          if (this.application_status.status === 'active') {
            this.application_status.status = 'block by vendor'
          } else {
            this.application_status.status = 'active'
          }
          this.application_status.business_profile_id = this.profile.id
          this.$store.dispatch('businessProfile/changeApplicationStatus', { item: this.application_status, param: this.profile_params, user_type: 'vendor' })
          this.$bvModal.hide('status-modal')
          // this.application_status.reason = ''
        }
      })
    },
    setAsInactiveData() {
      this.$bvModal.show('status-modal')
    },
    emptyProfile() {
      const dom = this
      setTimeout(() => {
        dom.profile = {
          member_id: null,
          name: '',
          phone: '',
          email: '',
          city: '',
          address: '',
          state_id: null,
          county_id: null,
          zip_code: '',
          lng: null,
          lat: null,
          license: '',
          website: '',
          category_id: 1,
          sub_category_id: null,
          tags: [],
          contact_name: '',
          contact_title: '',
          contact_email: '',
          contact_phone: '',
          description: '',
        }
      }, 200)
    },
    // selectTag(data) {
    //   console.log('add_tags', data)
    // },
    // onRemove(data) {
    //   console.log('remove_tags', data)
    // },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>
