<template>
  <div class="multiple-select-view">
    <div
      v-for="item in selectOptions"
      :key="item.id"
      class="tags_item_box"
    >
      <span>{{ item.title }}</span>
      <feather-icon
        icon="XIcon"
        @click="remove(item)"
      />
    </div>
    <input
      type="text"
      placeholder="select tag"
      @focus="showMenu"
      @focusout="hideMenu"
    >
    <div
      v-if="is_active"
      class="select_option_view"
    >
      <div
        v-for="(option, index) in options"
        :key="index"
        class="option-item"
        @click="selectItem(option)"
      >
        <span>{{ option.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    options: { type: Array },
    // eslint-disable-next-line vue/require-default-prop
    remove: { type: Function },
    // eslint-disable-next-line vue/require-default-prop
    selected: { type: Function },
    // eslint-disable-next-line vue/require-default-prop
    selectOptions: { type: Array },
  },
  data() {
    return {
      is_active: false,
    }
  },
  methods: {
    hideMenu() {
      const dom = this
      setTimeout(() => {
        dom.is_active = false
      }, 1000)
    },
    showMenu() {
      this.is_active = true
    },
    selectItem(item) {
      this.selected(item)
    },
  },
}
</script>

<style scoped>

</style>
